<template>
    <div v-if="!toggleVoucher" class="min-h-[140px] relative ticket-wrap h-full">
        <div
            :id="cloneData.id"
            :key="cloneData.id"
            v-click-outside="hideDetail"
            class="rounded-lg w-full "
            :class="`${!showDetail ? 'ticket' : ''} ${ (product && !cloneData.recheckStatus && !cloneData.loadingRecheck) ? 'opacity-25' : '' }`"
        >
            <div v-if="!forceRecheckStatus">
                <div v-if="status" class="absolute sm:top-2 sm:left-2 top-1 right-1">
                    <i class="text-xl" :class="'el-icon-' + status.icon" :style="{color: status.color}" />
                </div>
            </div>
            <div
                v-if="!showDetail"
                class="flex flex-col justify-between cursor-pointer pt-3 pb-2 mx-auto relative h-full"
                @click="handleShowDetail"
            >
                <div class="flex h-full">
                    <div class="logo-supplier">
                        <div class="flex flex-col justify-start border-r-2 border-dashed pr-2 ml-6 min-w-[95px] items-center">
                            <nuxt-img
                                v-if="getLogo"
                                class="rounded-full h-10 w-10 object-contain"
                                :placeholder="[40, 40, 10]"
                                loading="lazy"
                                width="40"
                                height="40"
                                :src="toPortalImage(getLogo)"
                                :style="{
                                    backgroundColor: cloneData?.supplier?.slug ==='shopee' && cloneData?.voucherCode?.includes('FSV-') ?
                                        '#26A99A' : (cloneData?.supplier?.colorCode || '#FF5722'),
                                    padding: cloneData?.supplier?.slug ==='shopee' ? '4px' : 0}"
                            />
                            <div v-else class="w-10 h-10">
                                {{ cloneData?.voucherCategory?.title }}
                            </div>
                            <div class="flex flex-col justify-end">
                                <span class="font-semibold mt-1 text-center line-clamp-2 text-[12px]">
                                    {{ (cloneData.brand?.name || cloneData?.voucherCategory?.title || cloneData.supplier?.name) | formatTitle }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="pl-3 pr-5 w-full">
                        <div>
                            <div class="mini-title-supplier font-semibold text-left relative">
                                <span>
                                    {{ cloneData.brand?.name || cloneData?.voucherCategory?.title || cloneData.supplier?.name | formatTitle }}
                                </span>
                                <nuxt-img
                                    class="rounded-full p-1 right-0 top-0 absolute"
                                    :placeholder="[40, 40, 10]"
                                    loading="lazy"
                                    width="40"
                                    height="40"
                                    :src="toPortalImage(getLogo)"
                                    :style="{backgroundColor: cloneData?.supplier?.colorCode || '#FF5722'}"
                                />
                            </div>
                            <div class="text-xs mb-1 flex items-center gap-x-1">
                                <div v-if="cloneData.isPromotion" class="text-sm font-semibold">
                                    <span v-if="highlightPos<0">
                                        {{ cloneData?.title }}
                                    </span>
                                    <span v-else>
                                        {{ cloneData?.title?.slice(0, highlightPos) }}
                                        <span class="text-xl font-bold text-[#008A07]">{{ cloneData?.titleHighlight }}</span>
                                        {{ cloneData?.title?.slice(highlightPos + cloneData?.titleHighlight?.length, cloneData?.title?.length) }}
                                    </span>
                                </div>
                                <template v-else>
                                    <div class="text-sm font-semibold">
                                        {{ cloneData.voucherType === 'value' || cloneData.voucherType === 'percent' ? 'Giảm ' : 'Hoàn' }}
                                    </div>

                                    <div class="font-bold text-lg md:text-2xl mr-1" style="color: #008A07">
                                        <span v-if="cloneData.voucherType === 'coin' && cloneData.voucherAmount < 1000 || cloneData.voucherType === 'percent'">
                                            {{ cloneData.voucherAmount }}%
                                        </span>
                                        <span v-else>
                                            {{ cloneData.voucherAmount / 1000 | formatNumber }}K {{ cloneData.voucherType === 'coin' ? 'Xu' : '' }}
                                        </span>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-if="!cloneData.isPromotion" class="text-xs mb-1">
                            <span class="font-normal">ĐH tối thiểu: </span>
                            <span class="font-semibold">{{ (cloneData.minSpend || 0) | formatNumber }}đ</span>
                            <span v-if="cloneData.detailLink"> - Còn: <span class="font-semibold">{{ 100 - (cloneData.percentageUsed || cloneData.percentage_used || 0) }}%</span></span>
                        </div>
                        <div v-if="cloneData.targetUser == 'new'" class="text-xs mb-1">
                            <span class="font-normal">Đối tượng: </span>
                            <span class="font-semibold">
                                {{ getTargetLabel }}
                            </span>
                        </div>
                        <div class="flex items-center mb-1">
                            <span class="italic text-xs text-left text-[#008a08] text-[12px] font-semibold">
                                Lưu ý: {{ cloneData.note?.slice(0, 35) }}... <span class="text-black font-normal text-[11px]">Xem chi tiết</span>
                            </span>
                        </div>
                        <div v-if="showbuymore && cloneData.minSpend > productModel.price / 100000" class="flex items-center mb-1">
                            <span class="text-left" style="margin-top:1px;margin-right: 5px; min-width:fit-content; color:#ff5722;font-size:12px">
                                Mua thêm {{ cloneData.minSpend - productModel.price / 100000 | formatNumber }}đ để sử dụng voucher này
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex mini-action justify-between pr-2">
                    <VoucherTime :voucher="voucher" />
                    <div class="flex w-full justify-between items-center text-[#008A07] bor">
                        <template v-if="!cloneData?.isPromotion">
                            <div v-if="!cloneData.listApplyLink" class="text-[12px] sm:text-sm hashtag flex border-2 border-solid border-green-600  px-2 rounded max-[390px]:ml-2">
                                <span class="font-mono italic">
                                    #
                                </span>
                                <p class="font-sans italic">
                                    {{ VOUCHER_TYPE[voucher.type] }}
                                </p>
                            </div>
                            <div v-else>
                                <a
                                    class="italic underline pl-2"
                                    rel="nofollow"
                                    target="_blank"
                                    :href="cloneData.listApplyLink"
                                >List áp dụng</a>
                            </div>
                        </template>
                        <div v-else />
                        <VoucherButton :voucher="voucher" :product-link="product?.affLink || ''" />
                    </div>
                </div>
            </div>
            <VoucherDetail
                v-if="showDetail"
                :voucher="cloneData"
                :target-user="getTargetLabel"
                @hideDetail="hideDetail"
            />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import ClickOutside from 'vue-click-outside';
    import _find from 'lodash/find';
    import _isEmpty from 'lodash/isEmpty';
    import dayjs from 'dayjs';
    import cloneDeep from 'lodash/cloneDeep';
    import {
        NORMAL as TARGET_NORMAL,
        OPTIONS as TAGRET_OPTIONS,
        VOUCHER_TYPE,
    } from '~/constants/voucher/target';
    import { image as toImage, toPortalImage } from '~/utils/url';
    import VoucherDetail from '@/components/shared/VoucherDetail.vue';
    import VoucherButton from '@/components/shared/VoucherButton.vue';
    import VoucherTime from '@/components/shared/VoucherTime.vue';

    const isVisiable = (elem) => (!!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length));
    export default {
        directives: {
            ClickOutside,
        },
        components: {
            VoucherDetail,
            VoucherButton,
            VoucherTime,
        },
        props: {
            id: {
                type: [String, Number],
            },
            category: {
                type: Object,
            },
            voucher: {
                type: Object,
                required: true,
            },
            product: {
                type: Object,
            },
            suppliers: {
                type: Array,
                required: true,
            },
            showbuymore: {
                type: Boolean,
            },
            toggleVoucher: {
                type: Boolean,
            },
        },

        data() {
            const cloneData = cloneDeep(this.voucher);
            return {
                cloneData,
                TAGRET_OPTIONS,
                VOUCHER_TYPE,
                showDetail: false,
                statusCopy: false,
            };
        },

        computed: {
            ...mapState('productFinded', ['productModel']),
            ...mapState('voucher', ['copyStatus', 'forceRecheckStatus', 'totalRecheckStatusFalse', 'totalCheckedStatus']),
            externalLink() {
                return `${process.env.API_HOST}/tracking/coupon/${this.voucher.offer}/${this.voucher.couponCode || this.voucher.id}`;
            },

            getTargetLabel() {
                const _value = this.voucher.targetUser || TARGET_NORMAL;
                const target = _find(TAGRET_OPTIONS, (option) => option.value === _value);

                return target.label;
            },

            supplier() {
                return _find(this.suppliers, (supplier) => supplier.slug === this.voucher.offer);
            },
            isLoggedIn() {
                return this.$store.state.auth.loggedIn;
            },
            isStarted() {
                return this.cloneData;
            },
            getLogo() {
                if (this.cloneData?.brand?.avatar) {
                    return this.cloneData?.brand?.avatar;
                }
                if (this.cloneData?.avatar) {
                    return this.cloneData?.avatar;
                }
                return this.cloneData?.supplier?.avatar;
            },
            status() {
                if (this.cloneData.loadingRecheck) {
                    return { icon: 'loading' };
                }
                if (this.cloneData.forceRecheckStatus && this.cloneData.id && !this.cloneData.loadingRecheck) {
                    return { icon: 'success', color: '#16a34a' };
                }
                if (this.product && !this.cloneData.forceRecheckStatus && !this.cloneData.loadingRecheck) {
                    return { icon: 'error', color: '#4b5563' };
                }

                return null;
            },
            highlightPos() {
                return this.cloneData?.title?.search(this.cloneData?.titleHighlight);
            },
        },
        mounted() {
            this.recheckVoucher();
        },
        methods: {
            _isEmpty,
            dayjs,
            toImage,
            toPortalImage,
            ...mapActions('authForm', ['setStatusFormLogin']),
            ...mapActions('voucher', ['setVoucherCode', 'setStatus', 'setCopyStatus', 'setTotalRecheckStatusFalse', 'setTotalCheckedStatus']),
            hideDetail(event) {
                if (event.voucherId !== this.voucher.id) {
                    this.showDetail = false;
                }
            },
            handleShowDetail(event) {
                this.showDetail = (this.cloneData.product && !this.cloneData.recheckStatus && this.cloneData.id) ? false : !this.showDetail;
                event.voucherId = this.voucher.id;
            },
            listApply(link) {
                const cleanLink = link.replace(/(%26voucherCode)([^&])\w+/g, '');
                window.open(cleanLink, '_blank', 'noreferrer,noopener');
            },
            recheckVoucher() {
                if (this.voucher.id && this.product && isVisiable(this.$el.parentElement.parentElement)) {
                    if (this.forceRecheckStatus) {
                        this.cloneData.recheckStatus = true;
                    } else if (this.cloneData.forceRecheckStatus !== undefined) {
                        this.cloneData.loadingRecheck = false;
                        this.cloneData.recheckStatus = this.cloneData.forceRecheckStatus;
                    } else {
                        this.$api.shopeeVoucher.getProductByPromotion({ promotionId: this.voucher.couponId, keySearch: this.voucher.product?.name }).then((result) => {
                            this.setTotalCheckedStatus(this.totalCheckedStatus + 1);
                            this.cloneData.loadingRecheck = false;
                            this.cloneData.recheckStatus = result.data?.filter(
                                (item) => item.itemid === parseInt(this.voucher.product.itemid, 10)
                                    && item.shopid === parseInt(this.voucher.product.shopid, 10),
                            ).length > 0;
                            if (!this.cloneData.recheckStatus) {
                                this.setTotalRecheckStatusFalse(this.totalRecheckStatusFalse + 1);
                            }
                        });
                    }
                    this.$forceUpdate();
                }
            },
        },
    };
</script>

<style lang="scss" scoped>

@media (max-width: 768px) {
    .hashtag{
        display: -webkit-box !important;
        -webkit-line-clamp:1;
        text-overflow: ellipsis;
        overflow:hidden;

    }
}
</style>
