<template>
    <div
        :id="voucher.id"
        class="bg-white relative mx-auto pt-3 pb-2 px-3 md:px-6 mb-2 sm:absolute z-40 overflow-hidden rounded-lg shadow-lg border w-full"
        @click="event => $emit('hideDetail', event)"
    >
        <div v-if="voucher.isPromotion" class="text-sm font-semibold">
            <span v-if="highlightPos<0">
                {{ voucher?.title }}
            </span>
            <span v-else>
                {{ voucher.title?.slice(0, highlightPos) }}
                <span class="text-xl font-bold text-[#008A07]">{{ voucher?.titleHighlight }}</span>
                {{ voucher?.title?.slice(highlightPos + voucher?.titleHighlight?.length, voucher?.title?.length) }}
            </span>
        </div>
        <div v-else class="font-semibold text-xl md:text-3xl mt-1 mb-3" style="color: #008A07">
            {{ voucher.voucherType === 'value' || voucher.voucherType === 'percent' ? 'Giảm ' : 'Hoàn' }}

            <span v-if="voucher.voucherType === 'coin' && voucher.voucherAmount < 1000 || voucher.voucherType === 'percent'">
                {{ voucher.voucherAmount }}%
            </span>
            <span v-else>
                {{ voucher.voucherAmount / 1000 | formatNumber }}K {{ voucher.voucherType === 'coin' ? 'Xu' : '' }}
            </span>
        </div>
        <div v-if="!voucher.isPromotion" class="mb-3 mx-auto">
            Còn lại:  <span class="font-semibold">{{ 100 - (voucher.percentageUsed || voucher.percentage_used || 0) }}%</span>
        </div>
        <div>
            <div v-if="voucher.maxDiscount" class="text-sm mb-2">
                <span class="font-normal">Tối đa: </span>
                <span v-if="voucher.voucherType === 'value'" class="font-semibold">
                    {{ voucher.voucherAmount / 1000 | formatNumber }}K
                </span>
                <span v-if="voucher.voucherType === 'coin' && voucher.voucherAmount > 1000" class="font-semibold">
                    Hoàn {{ voucher.voucherAmount / 1000 | formatNumber }}K
                </span>
                <span v-if="voucher.voucherType === 'coin' && voucher.voucherAmount < 1000" class="font-semibold">
                    Hoàn {{ voucher.maxDiscount | formatNumber }} Xu
                </span>
                <span v-if="voucher.voucherType === 'percent'" class="font-semibold">
                    {{ voucher.maxDiscount | formatNumber }}đ
                </span>
            </div>
            <div v-if="!voucher.isPromotion" class="text-sm mb-2">
                <span class="font-normal">ĐH tối thiểu: </span>
                <span class="font-semibold">{{ voucher.minSpend || 0 | formatNumber }}đ</span>
            </div>
            <div class="text-sm mb-2">
                <span class="font-normal">{{ voucher.brand?.id ? "Áp dụng cho: " : "Ngành hàng: " }}</span>
                <span v-if="voucher.voucherCategory" class="font-semibold">
                    {{ voucher.voucherCategory?.title | formatTitle }}
                </span>
                <span v-else class="font-semibold">
                    Đang cập nhật
                </span>
                <span v-if="voucher.icon_text" class="font-semibold">
                    {{ voucher.icon_text }}
                </span>
            </div>
            <div v-if="voucher.targetUser == 'new'" class="text-sm mb-2">
                <span class="font-normal">Đối tượng: </span>
                <span class="font-semibold">
                    {{ targetUser }}
                </span>
            </div>
            <div v-if="voucher.usageTerms" class="text-sm mb-2">
                <span class="font-normal">Thể lệ: </span>
                <div class="font-semibold usageTerms whitespace-pre-line html-text" v-html="voucher.usageTerms" />
            </div>
            <div v-if="voucher.note" class="text-sm mb-2">
                <span class="font-bold" style="color:#ff5722">Lưu ý: </span>
                <span class="font-semibold usageTerms whitespace-pre-line">
                    {{ voucher.note }}
                </span>
            </div>
        </div>
        <div class="flex justify-center pt-3">
            <div class="flex justify-center items-center text-[#008A07]">
                <template v-if="!voucher.isPromotion">
                    <div v-if="!voucher.listApplyLink" class="hashtag flex ml-1 border-2 border-solid border-green-600 px-2 rounded">
                        <span class="font-mono italic">
                            #
                        </span>
                        <p class="font-sans italic">
                            {{ VOUCHER_TYPE[voucher.type] }}
                        </p>
                    </div>
                    <div v-if="voucher.listApplyLink">
                        <a
                            class="italic underline"
                            rel="nofollow"
                            target="_blank"
                            :href="voucher.listApplyLink"
                        >List áp dụng</a>
                    </div>
                </template>
                <VoucherButton :voucher="voucher" />
            </div>
        </div>
    </div>
</template>
<script>
    import { VOUCHER_TYPE } from '@/constants/voucher/target';
    import VoucherButton from '@/components/shared/VoucherButton.vue';

    export default {
        components: { VoucherButton },
        props: {
            voucher: {
                type: Object,
                required: true,
            },
            targetUser: {
                type: String,
            },
        },
        data() {
            return {
                VOUCHER_TYPE,
            };
        },
        computed: {
            highlightPos() {
                return this.voucher?.title?.search(this.voucher?.titleHighlight);
            },
        },
    };
</script>
<style lang="">

</style>
